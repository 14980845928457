<!-- 存款 -->
<template>
  <div class="views-Deposit">
    <div class="views-title">{{ $t("userPage.text66") }}</div>
    <!-- <Tab @tab="tab" :tabIndex="0" :arr="['会员存款', '会员提款']" /> -->
    <div class="deposit-Type">{{ $t("userPage.text67") }}</div>
    <!-- 充值类型 -->
    <div class="deposit-tabs">
      <div
        class="deposit-tab"
        :class="{ 'deposit-tab-active': key == 'depositOffline' }"
        v-show="depositOffline.length"
        @click="tabType('depositOffline')"
      >
        {{ $t("userPage.text68") }}
      </div>
      <div
        class="deposit-tab"
        :class="{ 'deposit-tab-active': key == 'depositOnline' }"
        v-show="depositOnline.length"
        @click="tabType('depositOnline')"
      >
        {{ $t("userPage.text69") }}
      </div>
      <div
        class="deposit-tab"
        :class="{ 'deposit-tab-active': key == 'depositDigit' }"
        v-show="depositDigit.length"
        @click="tabType('depositDigit')"
      >
        {{ $t("userPage.text70") }}
      </div>
      <div
        class="deposit-tab"
        :class="{ 'deposit-tab-active': key == 'depositVips' }"
        v-show="depositVips.length"
        @click="tabType('depositVips')"
      >
        {{ $t("userPage.text71") }}
      </div>
    </div>

    <!-- 充值渠道 -->
    <div class="deposit-block deposit-block-my">
      <!-- <div class="deposit-block-title">充值渠道</div> -->
      <div class="deposit-block-content">
        <div
          @click="tabChannel(item)"
          :class="{ 'deposit-channel-activeitem': form.channelID == item.id }"
          class="deposit-channel-item"
          v-for="item in currChannels"
          :key="item.id"
        >
          <div class="deposit-channel-img">
            <div class="recharge-promotion" v-if="item.scale && item.scale > 0">
              <span class="recharge-promotion__text"
                >{{ $t("userPage.text72") }}{{ item.scale * 100 }}%</span
              >
            </div>
            <img
              loading="lazy"
              v-lazy="systemImgHost + item.icon"
              class="model-img"
            />
          </div>
          <div class="deposit-channel-name">{{ item.name }}</div>
        </div>
      </div>
    </div>

    <!-- 充值通道 -->
    <div class="deposit-block">
      <div class="deposit-block-title">{{ $t("userPage.text73") + ":" }}</div>
      <div class="deposit-block-content">
        <!-- currency 及 vip 渠道 下的通道 -->
        <div v-if="['currency', 'vip'].includes(currChannel.code)">
          <!-- 通道列表 -->
          <div
            @click="tabPassage(item)"
            :class="{ 'deposit-passage-activeitem': form.passageID == item.id }"
            class="deposit-passage-item"
            v-for="item in currChannel.channels"
            :key="item.id"
          >
            {{ item.name }}
          </div>
          <!-- 通道说明-->
          <div>
            <div>{{ $t("userPage.text74") }}</div>
            <div>{{ $t("userPage.text75") }}</div>
            <div>{{ $t("userPage.text76") }}</div>
            <div>{{ $t("userPage.text77") }}</div>
            <div>{{ $t("userPage.text78") }}</div>
          </div>
        </div>

        <!-- 其他类型的通道 -->
        <div v-if="!['currency', 'vip'].includes(currChannel.code)">
          <!-- 通道属于 union -->
          <div v-if="currChannel.code == 'union'">
            <Select v-model="form.passageID">
              <Option
                v-for="item in currChannel.channels"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></Option>
            </Select>
          </div>
          <!-- 通道不属于 union -->
          <div v-if="currChannel.code != 'union'">
            <div
              @click="tabPassage(item)"
              :class="{
                'deposit-passage-activeitem': form.passageID == item.id,
              }"
              class="deposit-passage-item"
              v-for="item in currChannel.channels"
              :key="item.id"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 存款姓名 -->
    <div class="deposit-block" v-if="currPassage.online == 1">
      <div class="deposit-block-title">{{ $t("userPage.text79") + ":" }}</div>
      <div class="deposit-block-content">
        <Input v-model.trim="form.name"></Input>
        <div class="deposit-tips">{{ $t("userPage.text80") }}</div>
      </div>
    </div>

    <!-- 充值金额 -->
    <div class="deposit-block">
      <div class="deposit-block-title">{{ $t("userPage.text81") }}:</div>
      <!-- 输入类型 -->
      <div class="deposit-block-content" v-if="isInput">
        <InputNumber
          v-model="form.money"
          :controls="false"
          :min="0"
          :precision="2"
        ></InputNumber>
        <div class="deposit-tips">
          <span>{{ $t("userPage.text82") }} {{ moneyMin }}；</span>
          <span>{{ $t("userPage.text83") }} {{ moneyMax }}</span>
        </div>
      </div>
      <!-- 选择类型 -->
      <div class="deposit-block-content" v-if="!isInput">
        <div class="radio-list">
          <Radio
            v-model="form.money"
            :value="item"
            :label="item"
            v-for="item in moneyRadios"
            :key="item"
            >{{ item }}</Radio
          >
        </div>
      </div>
    </div>

    <!-- 确认按钮 -->
    <div class="deposit-block">
      <div class="deposit-block-title"></div>
      <div class="deposit-block-content">
        <Button class="deposit-btn" @click="submit">下一步</Button>
      </div>
    </div>

    <!-- 验证码弹窗 -->
    <VerificationCode ref="veriCode" @pass="recharge" />
  </div>
</template>

<script>
import {
  _depositSetting,
  _getPaymentNextStep,
} from "../../../core/api/modules/finance";
import { _getMoneyModeAction } from "../../../core/comp/pages/finance";
import { mapGetters } from "vuex";
import {
  Select,
  Option,
  Input,
  InputNumber,
  Radio,
  Button,
  Message,
  MessageBox,
} from "element-ui";
import VerificationCode from "./VerificationCode.vue";

export default {
  components: {
    Select,
    Option,
    Input,
    InputNumber,
    Radio,
    Button,
    VerificationCode,
  },
  computed: {
    currChannels() {
      // 当前选择的类型
      if (this.key) return this[this.key];
      return [];
    },
    currChannel() {
      // 当前选择的渠道
      return this.currChannels.find((e) => e.id == this.form.channelID) || {};
    },
    currPassage() {
      // 当前选择的通道
      if (this.currChannel.channels && this.currChannel.channels.length) {
        return (
          this.currChannel.channels.find((e) => e.id == this.form.passageID) ||
          {}
        );
      }
      return {};
    },
    isInput() {
      // 当前通道的金额是否是输入类型（如果不是则是Radio选择类型）
      if (this.currPassage.amount && this.currPassage.amount.includes(",")) {
        return false;
      }
      return true;
    },
    moneyRadios() {
      // 金额为选择类型时的选项
      if (this.isInput) return [];
      if (!this.currPassage.amount) return [];
      return this.currPassage.amount.split(",").filter((s) => s && s.trim());
    },
    moneyMax() {
      // 金额为输入类型时的最小值
      if (!this.isInput) return 0;
      if (!this.currPassage.amount) return 0;
      let max = this.currPassage.amount.split("-")[1];
      return max || this.moneyMin; // ‘100-’ 的情况是指 100-100
    },
    moneyMin() {
      // 金额为输入类型时的最大值
      if (!this.isInput) return 0;
      if (!this.currPassage.amount) return 0;
      return this.currPassage.amount.split("-")[0];
    },
    ...mapGetters([
      "depositSmsCheck", // 充值时是否需要短信验证
      "depositOffline", // 线下充值
      "depositOnline", // 线上充值
      "depositDigit", // 数字货币
      "depositVips", // vip通道
      "depositSmsCheck", // 是否需要短信验证
      "userData", // 用户数据
      "systemImgHost",
    ]),
  },
  data() {
    return {
      key: "", // 支付方式  对应vuex中的数据key
      form: {
        // 充值表单
        channelID: "", // 渠道ID
        passageID: "", // 通道ID
        name: "", // 存款姓名
        money: "", // 存款金额
      },
    };
  },
  created() {
    _depositSetting(); // 获取是否需要短信验证
    // 获取通道数据
    this.getChannels();

    setTimeout(() => {
      console.log("----当前所有渠道", this.currChannels);
      console.log("----当前渠道", this.currChannel);
      console.log("----当前通道", this.currPassage);
    }, 5000);
  },
  methods: {
    // 充值
    submit() {
      // 存款姓名校验
      if (this.currPassage.online == 1 && this.form.name === "")
        return Message.error(this.$t("userPage.text84"));
      // 支付金额校验
      if (!this.form.money || this.form.money <= 0)
        return Message.error(this.$t("userPage.text85"));
      // 支付金额范围
      if (
        this.isInput &&
        (this.form.money < this.moneyMin || this.form.money > this.moneyMax)
      )
        return Message.error(this.$t("userPage.text86"));
      // 判断是否需要短信
      if (this.depositSmsCheck && !(this.userData && this.userData.bindPhone)) {
        this.$refs.veriCode();
        return;
      }
      this.recharge();
    },
    // 充值请求
    recharge() {
      // vip渠道
      if (this.key == "depositVips")
        return window.open(this.currPassage.vendorcode);
      // 其他渠道
      const params = {
        money: this.form.money,
        online: this.currPassage.online,
        channelId: this.currPassage.id,
        payType: this.currPassage.paytype,
        terminal: 1,
        vendorcode: this.currPassage.vendorcode,
        vendorname: this.currPassage.vendorname,
        vendorNickName: this.currPassage.name,
        transferName: this.form.name,
      };
      _getPaymentNextStep(params).then((res) => {
        // 刷新通道
        setTimeout(() => {
          this.getChannels();
        }, 1000);

        if (!(res && res.code == 0))
          return Message.error(res.msg || this.$t("userPage.text87"));
        console.log(this.key, this.currPassage.paytype);
        // 线下公司入款跳转内链
        if (
          this.key == "depositOffline" &&
          this.currPassage.paytype == "union"
        ) {
          // 公司入款跳转内链
          const query = res.data.url.split("?")[1];
          const url = this.$router.resolve({
            name: "bankRecharge",
            query: {
              query: query,
            },
          });
          window.open(url.href);
          return;
        }

        // 其他入款方式
        window.open(res.data.url, "_blank");
      });
    },
    // 切换通道
    tabPassage(item) {
      this.form.passageID = item.id;
      setTimeout(() => {
        if (this.currPassage.remark) {
          MessageBox.alert(this.currPassage.remark, this.$t("userPage.text61"));
        }
      }, 300);
    },
    // 切换渠道
    tabChannel(item) {
      this.form.channelID = item.id;
      setTimeout(() => {
        // 选择该渠道下第一个通道作为默认通道
        if (this.currChannel.channels && this.currChannel.channels.length) {
          this.tabPassage(this.currChannel.channels[0]);
        }
      }, 0);
    },
    // 切换付款方式
    tabType(key) {
      this.key = key;
      setTimeout(() => {
        // 选择付款类型下的第一个渠道作为默认渠道
        if (this.currChannels[0]) {
          this.tabChannel(this.currChannels[0]);
        }
      }, 0);
    },
    // 跳转
    // tab (val) {
    //     switch (val) {
    //         case 1:
    //             this.$router.push({ name: 'withdraw' })
    //             break
    //     }
    // },
    // 获取通道数据
    getChannels() {
      this.form = {
        // 充值表单
        channelID: "", // 渠道ID
        passageID: "", // 通道ID
        name: "", // 存款姓名
        money: "", // 存款金额
      };
      _getMoneyModeAction().then((res) => {
        if (res) {
          // 初始化渠道列表，默认顺序为（公司入款、线上入款、数字货币、VIP专享）中第一个有数据的类型
          if (res.offline.length) this.key = "depositOffline";
          else if (res.online.length) this.key = "depositOnline";
          else if (res.digit.length) this.key = "depositDigit";
          else if (res.vips.length) this.key = "depositVips";

          // 初始化渠道, 从当前渠道列表中选择第一个渠道作为默认渠道
          setTimeout(() => {
            if (this.currChannels[0]) {
              this.tabChannel(this.currChannels[0]);
            }
          }, 0);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
$imgUrl: "~@/assets/images/my/";
.views-Deposit {
  width: 800px;
  position: relative;
  margin: 0 auto;
  padding: 40px 0px;
  box-sizing: border-box;
  .views-title {
    width: 100%;
    line-height: 40px;
    height: 40px;
    font-size: 36px;
    text-align: left;
    font-weight: 700;
  }
  .deposit-Type {
    margin: 10px 0 30px 0;
    color: #ce06cd;
    font-size: 24px;
  }
  .deposit-tabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 25px 0;
    .deposit-tab {
      margin-right: 20px;
      cursor: pointer;
      color: #ce06cd;
      font-size: 18px;
      border: 1px solid #ce06cd;
      font-weight: 700;
      padding: 7px 15px;
      box-shadow: 0 2px 1px 0px #ce06cd;
      border-radius: 6px;
    }
    .deposit-tab-active {
      color: #fff;
      box-shadow: 0 2px 1px 0px #ffffff;
      background: #ce06cd;
    }
  }
  .deposit-block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 15px 0;
  }
  .deposit-block-my {
    margin: 40px 0;
  }
  .deposit-block-title {
    width: 100px;
    line-height: 24px;
    padding: 8px 0px;
    box-sizing: border-box;
    font-size: 16px;
    width: 100px;
    font-weight: 700;
    color: #6f6f6f;
  }
  .deposit-block-content {
    flex: 1;
    height: 100%;
    .deposit-tips {
      color: red;
      font-size: 14px;
      line-height: 30px;
    }
    .deposit-btn {
      color: #fff;
      border: none;
      width: 200px;
      height: 40px;
      background: #ce06cd;
    }
    .radio-list {
      line-height: 40px;
    }
  }
  .deposit-channel-item {
    display: inline-block;
    margin-right: 40px;
    margin-bottom: 20px;
    cursor: pointer;
    .deposit-channel-img {
      position: relative;
      padding: 15px;
      border-radius: 6px;
      border: 1px solid #6f6f6f;
      .recharge-promotion {
        width: 60px;
        height: 60px;
        position: absolute;
        top: -10px;
        left: -5px;
        white-space: nowrap;
        background: url(#{$imgUrl}promotion-icon.png) no-repeat right
          bottom/contain;
        text-align: left;
        letter-spacing: -1px;
        .recharge-promotion__text {
          position: absolute;
          left: -6px;
          top: 16px;
          width: 60px;
          text-align: center;
          color: #fff;
          font-size: 12px;
          -webkit-transform: rotate(319deg);
          transform: rotate(319deg);
        }
      }
      img {
        width: 80px;
        height: 80px;
      }
    }
    .deposit-channel-name {
      margin-top: 15px;
      text-align: center;
    }
  }
  .deposit-channel-activeitem {
    .deposit-channel-img {
      border: 1px solid #ce06cd;
      background: url(#{$imgUrl}deposit-active.png) no-repeat right
        bottom/contain;
      background-size: 25px 25px;
    }
    .deposit-channel-name {
      color: #ce06cd;
    }
  }
  .deposit-passage-item {
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;
  }
  .deposit-passage-activeitem {
    padding-left: 25px;
    line-height: 40px;
    color: #ce06cd;
    background: url(#{$imgUrl}deposit-icon.png) no-repeat left center/14px;
  }
}
</style>
<style lang="scss">
// 存款
.views-Deposit {
  .deposit-block .deposit-block-content {
    flex: 1;
    .el-input {
      width: 400px;
      .el-input__inner {
        // color: #fff;
        background-color: transparent;
        border-radius: 4px;
        border: 1px solid #000;
      }
    }
    .el-input-number.is-without-controls .el-input__inner {
      text-align: left;
    }
    .el-radio {
      &.is-checked {
        .el-radio__label {
          color: #ce06cd;
        }
        .el-radio__inner {
          border-color: #ce06cd;
          background: transparent;
          &:after {
            width: 7px;
            height: 7px;
            background-color: #ce06cd;
          }
        }
      }
      .el-radio__inner {
        background: transparent;
        border-color: #ce06cd;
      }
    }
  }
}
</style>
